import { EnvironmentVariables } from '@helal_aytah/global_shared';

function parsBooleanAttribute(att: string | null | undefined): boolean {
	return (att && att.toLowerCase() === 'true') || false;
}
export { parsBooleanAttribute };

export function checkEnvironmentVariables({ env }: { env: TRequiredEnv }): void {
	try {
		EnvironmentVariables.check(env);
	} catch (error) {
		const err = error as Error;
		throw new Error(err.message);
	}
}
