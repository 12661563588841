'use client';

import { GlobalIconButton } from '@/components/UI/buttons';
import { parsBooleanAttribute } from '@/lib';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { CgMoon, CgScreen, CgSun } from 'react-icons/cg';
import { useTernaryDarkMode, useWindowSize } from 'usehooks-ts';
import styles from './ThemeMode.module.scss';

type TThemeModeProps = Readonly<{
	buttonWidth: number;
	buttonHeight: number;
	animationDuration?: 0.8 | (number & {});
	localStorageKey?: 'theme-mode' | (string & {});
}>;

function ThemeMode({
	buttonHeight,
	buttonWidth,
	animationDuration = 0.8,
	localStorageKey = 'theme-mode'
}: TThemeModeProps): React.JSX.Element {
	const durationRef = useRef<number>(animationDuration);
	const { width } = useWindowSize({ debounceDelay: 300 });
	const lightModeButtonRef = useRef<HTMLButtonElement>(null);
	const darkModeButtonRef = useRef<HTMLButtonElement>(null);
	const systemModeButtonRef = useRef<HTMLButtonElement>(null);

	const { isDarkMode, setTernaryDarkMode, ternaryDarkMode } = useTernaryDarkMode({
		localStorageKey
	});

	const [activeThemeMode, setActiveThemeMode] = useState<typeof ternaryDarkMode>('system');

	const initialActiveMode = useCallback(() => {
		setActiveThemeMode(ternaryDarkMode);
	}, [ternaryDarkMode]);

	const themeSwitcherRef = useRef<HTMLDivElement>(null);

	function moveMarker(themeSwitcherEl: HTMLDivElement, buttonEl: HTMLButtonElement, duration: number): void {
		themeSwitcherEl.classList.add(`${styles['active']}`);
		themeSwitcherEl.style.setProperty('--x', `${buttonEl.offsetLeft}px`);

		setTimeout(() => {
			themeSwitcherEl.classList.remove(`${styles['active']}`);
		}, duration * 1000);
	}

	useLayoutEffect(() => {
		if (typeof window === 'undefined') return;
		initialActiveMode();

		document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
	}, [isDarkMode, initialActiveMode]);

	useLayoutEffect(() => {
		if (
			typeof window === 'undefined' ||
			!lightModeButtonRef.current ||
			!darkModeButtonRef.current ||
			!systemModeButtonRef.current ||
			!themeSwitcherRef.current
		)
			return;
		const themeSwitcher = themeSwitcherRef.current;

		[lightModeButtonRef.current, darkModeButtonRef.current, systemModeButtonRef.current].forEach(button => {
			if (!parsBooleanAttribute(button.getAttribute('aria-checked'))) return;
			moveMarker(themeSwitcher, button, durationRef.current);
		});

		if (!themeSwitcher.style.getPropertyValue('--dur'))
			themeSwitcher.style.setProperty('--dur', `${durationRef.current}s`);
	}, [activeThemeMode, width]);

	const style = { '--__width': `${buttonWidth}px`, '--__height': `${buttonHeight}px` } as React.CSSProperties;

	return (
		<div className={styles['theme-switcher']} role="radiogroup" ref={themeSwitcherRef} style={style}>
			<GlobalIconButton
				width={buttonWidth}
				height={buttonHeight}
				role="radio"
				aria-checked={activeThemeMode === 'light'}
				onClick={() => {
					setTernaryDarkMode('light');
				}}
				aria-label="switch to light mode"
				elementType="Button"
				className={styles['btn']}
				ref={lightModeButtonRef}
			>
				<CgSun />
			</GlobalIconButton>
			<GlobalIconButton
				width={buttonWidth}
				height={buttonHeight}
				role="radio"
				aria-checked={activeThemeMode === 'system'}
				onClick={() => {
					setTernaryDarkMode('system');
				}}
				aria-label="switch to system mode"
				elementType="Button"
				className={styles['btn']}
				ref={systemModeButtonRef}
			>
				<CgScreen />
			</GlobalIconButton>
			<GlobalIconButton
				width={buttonWidth}
				height={buttonHeight}
				role="radio"
				aria-checked={activeThemeMode === 'dark'}
				onClick={() => {
					setTernaryDarkMode('dark');
				}}
				aria-label="switch to dark mode"
				elementType="Button"
				className={styles['btn']}
				ref={darkModeButtonRef}
			>
				<CgMoon />
			</GlobalIconButton>
		</div>
	);
}
export { ThemeMode };
