import React from 'react';

type TLogoProps = Readonly<{ width: number; height: number; colorSide: string; colorCenter: string }> &
	React.ComponentProps<'svg'>;

function Logo({ className, width, height, colorCenter, colorSide, ...otherProps }: TLogoProps): React.JSX.Element {
	const cSide = colorCenter || '#4c3b4d';
	const cCenter = colorSide || '#ffeedb';
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 270 102.427" {...otherProps}>
			<defs>
				<linearGradient id="a">
					<stop offset={0} stopColor={cSide} />
					<stop offset={0.5} stopColor={cCenter} />
					<stop offset={1} stopColor={cSide} />
				</linearGradient>
				<linearGradient id="b">
					<stop offset={0} stopColor={cSide} />
					<stop offset={0.5} stopColor={cCenter} />
					<stop offset={1} stopColor={cSide} />
				</linearGradient>
			</defs>
			<path
				fill="url(#a)"
				d="M21.24 21.32v-9.36h9.36V40h-9.36v-9.32H3.12v6.2h3.12v-3.12h3.12V40H0V27.52h24.4v9.36h3.08v-21.8H24.4v9.36H6.24v-9.36H3.12v9.36H0V11.96h9.36v9.36h11.88zm50.384 9.32 1.8-3.12 5.44 9.36h3.6c-.8-1.28-8.4-14.28-9.04-15.56l-7.84 13.6-2.92 5.08h-10.8c.6-1 4.64-8.04 8.52-14.8 2.28-3.96 4.8-8.4 7.64-13.24h3.6l-7.44 12.88-6.92 12.04h3.6c4.92-8.68 8-13.8 12.56-21.8.28.48 14.36 24.88 14.44 24.92h-10.8z"
				transform="matrix(2.21934 0 0 2.21934 38 -26.543)"
			/>
			<path
				fill="url(#b)"
				d="M15.3 5.98v4.68H4.68v1.56h7.88v1.56H3.12V9.1h10.62V7.54H1.56v10.9h1.56v-3.12h1.56V20H0V5.98h15.3zM33.764 20h-5.4l-1.82-3.14h-3.5V20h-4.66v-7.8l11.44.02c1.3 0 2.34-1.06 2.34-2.34 0-1.3-1.04-2.34-2.34-2.34h-9.88V9.1h9.88c.42.02.74.36.74.78s-.32.76-.74.78h-11.44V5.98h11.44c2.16 0 3.9 1.74 3.9 3.9 0 2.14-1.74 3.9-3.9 3.9l-9.88-.02v4.68h1.56V15.3h5.94c.64 1.16 1.72 2.94 1.82 3.14h1.8c-.16-.22-1.08-1.86-1.82-3.14h1.8c.66 1.14 1.32 2.32 1.86 3.2zM52.128 5.98v4.68h-10.62v1.56h7.88v1.56h-9.44V9.1h10.62V7.54h-12.18v10.9h12.18v-1.56h-10.62v-1.56h12.18V20h-15.3V5.98h15.3zm19.184 0v4.68h-10.62v1.56h7.88v1.56h-9.44V9.1h10.62V7.54h-12.18v10.9h12.18v-1.56h-10.62v-1.56h12.18V20h-15.3V5.98h15.3zm5.444 1.56v7.78h13.82V20h-15.38v-1.56h13.84v-1.56h-13.84V5.98h4.68v7.8h-1.56V7.54h-1.56zm26.124 7.78.9-1.56 2.72 4.68h1.8c-.4-.64-4.2-7.14-4.52-7.78l-3.92 6.8L98.4 20H93c.3-.5 2.32-4.02 4.26-7.4 1.14-1.98 2.4-4.2 3.82-6.62h1.8l-3.72 6.44-3.46 6.02h1.8c2.46-4.34 4-6.9 6.28-10.9.14.24 7.18 12.44 7.22 12.46h-5.4zm21.164-2.48V5.98h4.68V20h-4.68l-7.5-8.68v-2.4l8.22 9.52h2.4V7.54h-1.56v9.5l-8.22-9.5h-2.4v10.9h1.56v-4.72l1.56 1.8V20h-4.68V5.98h4.68zm22.824-6.86v4.7h-8.6c-1.18.1-2.08 1.12-2.08 2.32s.9 2.18 2.08 2.32h5.48v1.56h-5.48a3.91 3.91 0 0 1-3.62-3.9c0-2 1.54-3.7 3.54-3.86h7.12V7.54h-7.04v.02a5.443 5.443 0 0 0-5.14 5.44c0 2.9 2.28 5.28 5.14 5.44h7.04v-3.12h1.56V20h-8.6a7.002 7.002 0 0 1-6.7-7c0-3.78 2.96-6.84 6.7-7zm19.184 0v4.68h-10.62v1.56h7.88v1.56h-9.44V9.1h10.62V7.54h-12.18v10.9h12.18v-1.56h-10.62v-1.56h12.18V20h-15.3V5.98h15.3zM185.316 20h-5.4l-1.82-3.14h-3.5V20h-4.66v-7.8l11.44.02c1.3 0 2.34-1.06 2.34-2.34 0-1.3-1.04-2.34-2.34-2.34h-9.88V9.1h9.88c.42.02.74.36.74.78s-.32.76-.74.78h-11.44V5.98h11.44c2.16 0 3.9 1.74 3.9 3.9 0 2.14-1.74 3.9-3.9 3.9l-9.88-.02v4.68h1.56V15.3h5.94c.64 1.16 1.72 2.94 1.82 3.14h1.8c-.16-.22-1.08-1.86-1.82-3.14h1.8c.66 1.14 1.32 2.32 1.86 3.2z"
				transform="translate(0 73.287) scale(1.45697)"
			/>
		</svg>
	);
}
export { Logo };
