import React from 'react';
import styles from './Menu.module.scss';

type TMenuProps = Readonly<{ style: React.CSSProperties; children: React.ReactNode }> & React.ComponentProps<'menu'>;

function Menu({ style, children, className, ...otherProps }: TMenuProps): React.JSX.Element {
	return (
		<menu style={style} className={`${styles.menu} ${className || ''}`.trim()} {...otherProps}>
			{children}
		</menu>
	);
}

export { Menu };
