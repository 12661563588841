import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.5/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pagesComponents/footers/BottomFooter/BottomFooter.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pagesComponents/footers/BottomFooter/CopyRight.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pagesComponents/lightModeSchema/LightModeSchema.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pagesComponents/theme/ThemeMode.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pagesComponents/topHeader/TopHeader.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/redux/components/StoreProvider.component.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/styles.scss");
