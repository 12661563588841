import { type Dispatch, type SetStateAction } from 'react';
import { useIsomorphicLayoutEffect, useLocalStorage, useMediaQuery, useReadLocalStorage } from 'usehooks-ts';

const COLOR_SCHEME_QUERY = '(prefers-color-scheme: light)';
const LOCAL_STORAGE_KEY = 'light-mode-color-scheme';

export type TChangeLightModeSchemaOptions = {
	selectorName?: `data-${string}`;
	selectorValues: string[];
	themeModeLocalStorageKey?: string;
	localStorageKey?: string;
};

export type TChangeLightModeSchemaReturn = {
	isLightMode: boolean;
	currentSchema: string;
	setSchema: Dispatch<SetStateAction<string>>;
	toggleSchema: () => void;
};

export function useChangeLightModeSchema({
	selectorName = 'data-color-schema',
	selectorValues,
	themeModeLocalStorageKey = '',
	localStorageKey = LOCAL_STORAGE_KEY
}: TChangeLightModeSchemaOptions): TChangeLightModeSchemaReturn {
	const isLightOS = useMediaQuery(COLOR_SCHEME_QUERY, { defaultValue: false });

	const [schema, setSchema] = useLocalStorage(localStorageKey, selectorValues[0] || 'schema-1', {
		initializeWithValue: true
	});
	const themeMode = useReadLocalStorage(themeModeLocalStorageKey);
	const isLightMode = themeMode === 'light' || (themeMode === 'system' && isLightOS) || (!themeMode && isLightOS);

	function toggleSchema(): void {
		const modes: string[] = selectorValues;
		setSchema((prevMode): string => {
			const nextIndex = modes.indexOf(prevMode) + 1;

			return nextIndex < modes.length ? modes[`${nextIndex}`] : 'not-valid-schema';
		});
	}

	useIsomorphicLayoutEffect(() => {
		if (typeof window === 'undefined') return undefined;
		const isSchemaValid = selectorValues.includes(schema);
		if (!isSchemaValid) document.documentElement.removeAttribute(selectorName);
		if (isLightMode && isSchemaValid) document.documentElement.setAttribute(selectorName, schema);
		return (): void => {
			document.documentElement.removeAttribute(selectorName);
		};
	}, [isLightMode, schema, selectorName, selectorValues]);

	return {
		isLightMode,
		currentSchema: schema,
		setSchema,
		toggleSchema
	};
}
