'use client';

import { GlobalButton } from '@/components/UI/buttons';
import { Logo } from '@/components/UI/logo';
import { Menu } from '@/components/UI/menu';
import { parsBooleanAttribute } from '@/lib';
import { useAppSelector } from '@/redux';
import { type TParsedUserAgent } from '@helal_aytah/global_shared';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useIsClient, useWindowSize } from 'usehooks-ts';
import styles from './TopHeader.module.scss';

type TTopHeaderProps = Readonly<{
	userAgent: TParsedUserAgent;
}>;

type TMenuTransformStyle = { translateX: `${string}px`; scale: `${number}` };
type TMenuOpacityStyle = { opacity: `${number}` };

type TMenuState = {
	afStyle: TMenuTransformStyle;
	bfStyle: TMenuTransformStyle & TMenuOpacityStyle;
};

function TopHeader({ userAgent }: TTopHeaderProps): React.JSX.Element {
	const { hash: hashState } = useAppSelector(({ menuState }) => menuState);
	const logoRef = useRef<HTMLAnchorElement>(null);
	const proficiencyRef = useRef<HTMLAnchorElement>(null);
	const aboutMeRef = useRef<HTMLAnchorElement>(null);
	const skillRef = useRef<HTMLAnchorElement>(null);
	const contactRef = useRef<HTMLAnchorElement>(null);
	const { width } = useWindowSize({ debounceDelay: 300 });
	const isClient = useIsClient();
	const isMobile = isClient && width <= 600 && userAgent.device.type === 'mobile';

	const [stateAf, setStateAf] = useState<TMenuState['afStyle']>({
		translateX: '0px',
		scale: '0'
	});
	const [stateBf, setStateBf] = useState<TMenuState['bfStyle']>({
		opacity: '0',
		translateX: '0px',
		scale: '0'
	});

	const updateAfStyleHandler = useCallback(({ scale, translateX }: TMenuState['afStyle']): void => {
		setStateAf({ translateX, scale });
	}, []);
	const updateBfStyleHandler = useCallback(({ scale, translateX, opacity }: TMenuState['bfStyle']): void => {
		setStateBf({ opacity, translateX, scale });
	}, []);

	function onClickHandler(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
		const { offsetLeft, clientWidth } = e.currentTarget;
		updateAfStyleHandler({ scale: `${clientWidth / 100}`, translateX: `${offsetLeft}px` });
	}

	useLayoutEffect(() => {
		if (!isClient) return;

		if (!logoRef.current || !proficiencyRef.current || !aboutMeRef.current || !skillRef.current || !contactRef.current)
			return;

		[logoRef.current, proficiencyRef.current, aboutMeRef.current, skillRef.current, contactRef.current].forEach(el => {
			if (!parsBooleanAttribute(el.getAttribute('aria-current'))) return;
			updateAfStyleHandler({ scale: `${el.clientWidth / 100}`, translateX: `${el.offsetLeft}px` });
		});
	}, [isClient, updateAfStyleHandler]);

	useLayoutEffect(() => {
		if (!logoRef.current || !proficiencyRef.current || !aboutMeRef.current || !skillRef.current || !contactRef.current)
			return;
		[logoRef.current, proficiencyRef.current, aboutMeRef.current, skillRef.current, contactRef.current].forEach(el => {
			if (el.getAttribute('href') !== hashState) return;
			updateAfStyleHandler({ scale: `${el.clientWidth / 100}`, translateX: `${el.offsetLeft}px` });
		});
	}, [hashState, updateAfStyleHandler]);

	function onHoverHandler(e: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
		updateBfStyleHandler({
			scale: `${e.currentTarget.clientWidth / 100}`,
			translateX: `${e.currentTarget.offsetLeft}px`,
			opacity: '1'
		});
	}
	function onMouseLeaveHandler(): void {
		updateBfStyleHandler({ scale: '0', translateX: '0px', opacity: '0' });
	}

	const menuStyle = (
		!isMobile
			? {
					'--__af-translateX': stateAf.translateX,
					'--__af-scaleX': stateAf.scale,
					'--__bf-translateX': stateBf.translateX,
					'--__bf-scaleX': stateBf.scale,
					'--__bf-opacity': stateBf.opacity
				}
			: {
					'--__af-translateX': `0px`,
					'--__af-scaleX': `0`,
					'--__bf-translateX': `0px`,
					'--__bf-scaleX': `0`,
					'--__bf-opacity': '0'
				}
	) as React.CSSProperties;

	return (
		<header className={styles['header']}>
			<Menu onMouseLeave={onMouseLeaveHandler} style={menuStyle} className={styles['menu']}>
				<li onMouseEnter={onHoverHandler} className={styles['item__logo']}>
					<GlobalButton
						aria-label="home"
						ref={logoRef}
						aria-current={hashState === '/#home' || hashState === ''}
						elementType="Link"
						href={'/#home'}
						withoutBorder
						onClick={onClickHandler}
					>
						<Logo
							height={45}
							width={130}
							colorCenter="rgba(var(--color-foreground-rgb),1)"
							colorSide="rgba(var(--color-foreground-rgb),.5)"
						/>
					</GlobalButton>
				</li>
				{!isMobile && (
					<>
						<li onMouseEnter={onHoverHandler}>
							<GlobalButton
								aria-label="proficiency"
								ref={proficiencyRef}
								aria-current={hashState === '/#proficiency'}
								elementType="Link"
								href={'/#proficiency'}
								withoutBorder
								onClick={onClickHandler}
							>
								proficiency
							</GlobalButton>
						</li>
						<li onMouseEnter={onHoverHandler}>
							<GlobalButton
								aria-label="about-me"
								ref={aboutMeRef}
								aria-current={hashState === '/#about-me'}
								elementType="Link"
								href={'/#about-me'}
								withoutBorder
								onClick={onClickHandler}
							>
								about me
							</GlobalButton>
						</li>
						<li onMouseEnter={onHoverHandler}>
							<GlobalButton
								aria-label="skill"
								ref={skillRef}
								aria-current={hashState === '/#skill'}
								elementType="Link"
								href={'/#skill'}
								withoutBorder
								onClick={onClickHandler}
							>
								skill
							</GlobalButton>
						</li>
					</>
				)}
				<li onMouseEnter={onHoverHandler}>
					<GlobalButton
						aria-label="contact"
						ref={contactRef}
						aria-current={!isMobile ? hashState === '/#home' : hashState === '/#contact'}
						elementType="Link"
						href={!isMobile ? '/#home' : '/#contact'}
						withoutBorder
						onClick={onClickHandler}
					>
						contact
					</GlobalButton>
				</li>
			</Menu>
		</header>
	);
}

export { TopHeader };
