'use client';
import React, { useRef } from 'react';
import { Provider } from 'react-redux';
import { type TAppStore, makeStore } from '../store';

function StoreProvider({ children }: { children: React.ReactNode }): React.JSX.Element {
	const storeRef = useRef<TAppStore>();
	if (!storeRef.current) {
		storeRef.current = makeStore();
	}

	return <Provider store={storeRef.current}>{children}</Provider>;
}
export { StoreProvider };
