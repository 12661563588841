import { configureStore } from '@reduxjs/toolkit';
import { rooReducer } from '../reducers/rootReducer';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeStore = function makeStore() {
	return configureStore({
		reducer: rooReducer,
		devTools: process.env.NODE_ENV === 'development'
	});
};

export type TAppStore = ReturnType<typeof makeStore>;
export type TRootState = ReturnType<TAppStore['getState']>;
export type TAppDispatch = TAppStore['dispatch'];
