'use client';

import { GlobalIconButton } from '@/components/UI/buttons';
import { useChangeLightModeSchema } from '@/hooks';
import React, { useEffect, useState } from 'react';
import { IoIosColorPalette } from 'react-icons/io';
import { useIsClient } from 'usehooks-ts';
import styles from './LightModeSchema.module.scss';

type TLightModeSchema = Readonly<{
	buttonWidth: number;
	buttonHeight: number;
	animationDuration?: 0.8 | (number & {});
	localStorageKey?: 'theme-mode' | (string & {});
}>;

function LightModeSchema({ buttonHeight, buttonWidth }: TLightModeSchema): React.JSX.Element | null {
	const isClient = useIsClient();

	const switchButtonRef = React.useRef<HTMLButtonElement>(null);

	const { isLightMode, toggleSchema } = useChangeLightModeSchema({
		themeModeLocalStorageKey: 'theme-mode',
		selectorValues: ['schema-1', 'schema-2', 'schema-3']
	});
	const [switchBtnIsVisible, setSwitchBtnIsVisible] = useState<boolean>(false);

	useEffect(() => {
		if (isClient && isLightMode) {
			setSwitchBtnIsVisible(true);
		}
		if (!switchButtonRef.current) return undefined;
		const switchBtn = switchButtonRef.current;
		if (isClient && !isLightMode) switchBtn.classList.add(styles['btn--exiting']);
		setTimeout(() => {
			setSwitchBtnIsVisible(false);
		}, 1 * 1000);
		return (): void => {
			if (switchBtn) switchBtn.classList?.remove(styles['btn--exiting']);
		};
	}, [isClient, isLightMode]);

	return switchBtnIsVisible ? (
		<GlobalIconButton
			ref={switchButtonRef}
			className={styles['btn--effect']}
			elementType="Button"
			aria-label="change light mode color schema"
			height={buttonHeight}
			width={buttonWidth}
			onClick={toggleSchema}
			disabled={isClient && !isLightMode}
		>
			<IoIosColorPalette />
		</GlobalIconButton>
	) : null;
}

export { LightModeSchema };
